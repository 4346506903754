import * as React from "react"
import '../styles/global.scss';
import Helmet from "react-helmet";
import {appStoreUrl, googlePlayUrl} from "../utils/constants";

export default class DownloadApp extends React.Component<{}> {

    componentWillMount() {
        if (typeof window !== `undefined` && typeof navigator !== `undefined`) {
            const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
            const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
            if (isIOS || isMac) {
                window.location.replace(appStoreUrl);
            } else {
                window.location.replace(googlePlayUrl);
            }
        }
    }

    public render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>LOGGY.pl</title>
                </Helmet>
                Przekierowanie do sklepu...
            </div>
        )
    }
}
